import React from 'react';
import './MccObsCalendar.css';

const MccObsCalendar = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Milwaukee Curling Club OBS Calendar</h1>
        <p>This app will allow a user to connect to a google calendar.  Scripts can then be run to evaluate the current events happening on the calendar and start a youtube stream via the app.  They can do this by using the youtube API to create a broadcast, bind to a stream and manage the playlists.  The app will not use data for any other purposes.</p>
        <a href="/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
      </header>
      
    </div>
  );
}


export default MccObsCalendar;
