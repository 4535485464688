import { WebPubSubClient } from "@azure/web-pubsub-client";
import { SetStateAction, useEffect, useState } from "react";

interface StandingsRow {
    Row: string;
    Color: string;
    Size: number;
}


//create container
export function LeagueStandings() {
    //const [connection, setConnection] = useState<null | HubConnection>(null);
    const [client, setClient] = useState<null | WebPubSubClient>(null);
    const [standings, setStandings] = useState<StandingsRow[]>([]);

/*     useEffect(() => {
        const connect = new HubConnectionBuilder()
          .withUrl("Endpoint=https://leaguestandings.service.signalr.net;AccessKey=/DTC+IXG7D2Kk1YPYL+F9bBs0iDE3OTAWp3eSnrDejQ=;Version=1.0;")
          .withAutomaticReconnect()
          .build();
    
        setConnection(connect);
      }, []);

      useEffect(() => {
        if (connection) {
          connection
            .start()
            .then(() => {
              connection.on("UpdateStandings", (message) => {
                setStandings(message);
              });
            })
            .catch((error) => console.log(error));
        }
      }, [connection]); */


      useEffect(() => {
        //Make a get request to get connection string
        fetch('https://67m7uq3175.execute-api.us-east-1.amazonaws.com/Prod/api/IRacing')
            .then(response => response.text())
            .then(data => {
              const pubsubClient = new WebPubSubClient(data);
              setClient(pubsubClient);
            })
            .catch(error => {
                console.error('Error fetching connection string:', error);
            });


      }, []);

      useEffect(() => {
        if (client) {
          client
            .start()
            .then(() => {
              client.on("connected", () => {
                console.log("Connected to Azure Web PubSub");
              });
              client.on("disconnected", () => {
                console.log("Disconnected from Azure Web PubSub");
              });
              client.on("server-message", (message) => {
                const standingsData: StandingsRow[] = JSON.parse(String(message.message.data));
                setStandings(standingsData);
              });
            })
            .catch((error: any) => console.log(error));
            
        }
      }, [client]);
   

   if(!client || !standings || standings.length === 0) {
        return (
            <div>
                <h1>Awaiting Data</h1>
            </div>
        );
   }

    return (
        <div>
            {standings.map((standing, index) => (
                <div key={index} style={{color: standing.Color, fontSize: standing.Size}}>
                    {standing.Row}
                </div>
            ))}
        </div>
    )
}