import { useEffect, useState } from "react";
import { Game, SheetStats } from "./mccTrackermodels"
import  Select  from "react-select";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material';

//create container
export interface ISheetStatisticsContainerProps {
    games: Game[];
}


export function SheetStatisticsContainer({games}: ISheetStatisticsContainerProps) {

    const [sheetData, setSheetData] = useState<SheetStats[]>([]);


    const getStats = () => {
        var sheetStatsList: SheetStats[] = [];
        games.forEach((game: any) => {
            var sheetStat = sheetStatsList.find(s => s.sheet === game.sheet);
            if(sheetStat === undefined){
                sheetStat = { sheet: game.sheet, yellowWins: 0, redWins: 0, ties: 0 };
                sheetStatsList.push(sheetStat);
            }
            if(game.yellowTeamWon){
                sheetStat.yellowWins++;
            }
            else if(game.redTeamWon){
                sheetStat.redWins++;
            }
            else{
                sheetStat.ties++;
            }

        });
        return sheetStatsList;
    }



    useEffect(() => {
        setSheetData(getStats());
    }, [games]);

    const columns: GridColDef[] = [
        { field: 'sheet', headerName: 'Sheet', width: 150, type: 'singleSelect', valueOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] },
        { field: 'yellowWins', headerName: 'Yellow Wins', width: 150 },
        { field: 'redWins', headerName: 'Red Wins', width: 150 },
        { field: 'ties', headerName: 'Ties', width: 150 }
    ];

    return (
    <div>

        <h3>Stats by Sheet</h3>
        

        

        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={sheetData}
                columns={columns}
                getRowId={(row) => row.sheet}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'sheet', sort: 'asc' }],
                    },
                }}
            />
        </Box>
        
        {/* <h3>Stats by Sheet</h3>
        <div style={{display: 'flex'}}>
                <label style={{padding:'10px'}}>
                    Years:
                    <Select isMulti options={years.map((year) => ({ value: year, label: year }))} onChange={handleSelectedYearChanged} value={selectedYears.map((year) => ({value: year, label: year}))}/>
                </label>

                <label style={{padding:'10px'}}>
                    Semesters:
                    <Select isMulti options={semesters.map((semester) => ({ value: semester, label: semester }))} onChange={handleSelectedSemesterChanged} value={selectedSemesters.map((semester) => ({ value: semester, label: semester }))} />
                </label>

                <label style={{padding:'10px'}}>
                    Divisions:
                    <Select isMulti options={divisions.sort().map((division) => ({ value: division, label: division }))} onChange={handleSelectedDivisionChanged} value={selectedDivisions.map((division) => ({ value: division, label: division }))} />
                </label>

            </div>

        <ReactDataGrid idProperty="id" style={gridStyle} columns={columns} dataSource={getStats()} defaultSortInfo={{ name: 'sheet', dir: 1}} /> */}
    </div>                    
    )
}