import { useState, useEffect } from 'react';
import { FullDetails, Game, SummarizedDetails, allStatisticsColumns } from "./mccTrackermodels";
import '@inovua/reactdatagrid-community/index.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';


interface HighLevelStatsContainerProps {
    games: Game[];
}

export function HighLevelStatsContainer({ games } : HighLevelStatsContainerProps) {
    const [filteredGames, setFilteredGames] = useState<Game[]>([]);
    const [data, setData] = useState<SummarizedDetails[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [divisions, setDivisions] = useState<string[]>([]);
    const [semesters, setSemesters] = useState<string[]>([]);

    const [selectedYears, setSelectedYears] = useState<string[]>([]);
    const [selectedSemesters, setSelectedSemesters] = useState<string[]>([]);
    const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);

    const handleSelectedYearChanged = (selectedOption: any) => {
        const {
            target: { value },
        } = selectedOption;
        
        setSelectedYears(typeof value === 'string' ? value.split(',') : value);
    }

    const handleSelectedSemesterChanged = (selectedOption: SelectChangeEvent<typeof years>) => {
        const {
            target: { value },
        } = selectedOption;
        
        setSelectedSemesters(typeof value === 'string' ? value.split(',') : value,);
    }

    const handleSelectedDivisionChanged = (selectedOption: any) => {
        const {
            target: { value },
        } = selectedOption;

        setSelectedDivisions(typeof value === 'string' ? value.split(',') : value);
        
    }



    

    const columns: GridColDef[] = [
        { field: 'team', headerName: 'Team', width: 150 },
        { field: 'wins', headerName: 'Wins', width: 110 },
        { field: 'losses', headerName: 'Losses', width: 110 },
        { field: 'ties', headerName: 'Ties', width: 110 },
        { field: 'averagePts', headerName: 'Avg Points', width: 150 },
        { field: 'averagePtsAgainst', headerName: 'Avg Points Against', width: 150 },
        { field: 'hammerEfficiency', headerName: 'Hammer Efficiency', width: 180 },
        { field: 'forceEfficiency', headerName: 'Force Efficiency', width: 180 },
        { field: 'stealEfficiency', headerName: 'Steal Efficiency', width: 180 },
        { field: 'stealDefenseEfficiency', headerName: 'Steal Defense Efficiency', width: 200 },
        { field: 'hammerMinusSteal', headerName: 'Hammer minus Steal', width: 180 },
    ];

    useEffect(() => {
        const yearsAvailable = games.map((g: any) => g.year).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setYears(yearsAvailable);
        setSelectedYears(years);
        const divisionsAvailable = games.map((g: any) => g.division).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setDivisions(divisionsAvailable);
        setSelectedDivisions(divisionsAvailable);
        const semestersAvailable = games.map((g: any) => g.semester).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setSemesters(semestersAvailable);
        setSelectedSemesters(semestersAvailable);
    }, [games]);

    useEffect(() => {
        var eligibleGames = games.filter(g => (selectedYears.includes(g.year) || selectedYears.length === 0) && (selectedSemesters.includes(g.semester) || selectedSemesters.length === 0 )&& (selectedDivisions.includes(g.division) || selectedDivisions.length === 0));
        setFilteredGames(eligibleGames);
    }, [games, selectedYears, selectedSemesters, selectedDivisions]);

    useEffect(() => {
        const getTeamStats = () => {
            var teamDetailsList: FullDetails[] = [];
            var teamsToCalculate = filteredGames.map(g => g.red).concat(filteredGames.map(g => g.yellow)).filter((v, i, a) => a.indexOf(v) === i);
    
            teamsToCalculate.forEach(t => {
                var teamGames = filteredGames.filter(g => g.red === t || g.yellow === t);
                var teamWins = teamGames.filter(g => (g.redTeamWon && g.red === t) || (g.yellowTeamWon && g.yellow === t)).length;
                var teamLosses = teamGames.filter(g => (g.redTeamWon && g.yellow === t) || (g.yellowTeamWon && g.red === t)).length;
                var teamTies = teamGames.filter(g => g.tie).length;
    
                var teamDetails = {
                    id: t,
                    name: t,
                    team: t,
                    wins: teamWins,
                    losses: teamLosses,
                    ties: teamTies,
                    ptsFor: [],
                    ptsAgainst: [],
                    hammerSuccesses: 0,
                    hammerFailures: 0,
                    forces: 0,
                    forceFailures: 0,
                    steals: 0,
                    stealFailures: 0,
                    concededSteals: 0,
                    concededStealFailures: 0,
                } as FullDetails;
    
                teamGames.forEach(g => {
                    if (g.red === t) {
                        calculateEndStatsForRed(teamDetails, g);
                    } else {
                        calculateEndStatsForYellow(teamDetails, g);
                    }
                });
                teamDetailsList.push(teamDetails);
    
            });
            return convertFullDetailsToSummarizedDetails(teamDetailsList);
    
        }
    
        const calculateEndStatsForRed = (teamDetails: FullDetails, game: Game) => {
            var pointsInGame = game.scoreboard.reduce((a, b) => a + b.redScore, 0);
            var pointsAgainstInGame = game.scoreboard.reduce((a, b) => a + b.yellowScore, 0);
            var hammerConversions = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.redScore >= 2).length;
            var hammerFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && (e.redScore === 1 || e.yellowScore >= 1)).length;
    
            var forces = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore === 1).length;
            var forceFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore > 1).length;
    
            var steals = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore >= 1).length;
            var stealFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore === 0).length;
    
            var concededSteals = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.yellowScore >= 1).length;
            var concededStealFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.yellowScore === 0).length;
    
    
            teamDetails.ptsFor.push(pointsInGame);
            teamDetails.ptsAgainst.push(pointsAgainstInGame);
            teamDetails.hammerSuccesses += hammerConversions;
            teamDetails.hammerFailures += hammerFailures;
            teamDetails.forces += forces;
            teamDetails.forceFailures += forceFailures;
            teamDetails.steals += steals;
            teamDetails.stealFailures += stealFailures;
            teamDetails.concededSteals += concededSteals;
            teamDetails.concededStealFailures += concededStealFailures;
        }
    
        const calculateEndStatsForYellow = (teamDetails: FullDetails, game: Game) => {
            var pointsInGame = game.scoreboard.reduce((a, b) => a + b.yellowScore, 0);
            var pointsAgainstInGame = game.scoreboard.reduce((a, b) => a + b.redScore, 0);
            var hammerConversions = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.yellowScore >= 2).length;
            var hammerFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && (e.yellowScore === 1 || e.redScore >= 1)).length;
    
            var forces = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore === 1).length;
            var forceFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore > 1).length;
    
            var steals = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore >= 1).length;
            var stealFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore === 0).length;
    
            var concededSteals = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.redScore >= 1).length;
            var concededStealFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.redScore === 0).length;
    
            teamDetails.ptsFor.push(pointsInGame);
            teamDetails.ptsAgainst.push(pointsAgainstInGame);
            teamDetails.hammerSuccesses += hammerConversions;
            teamDetails.hammerFailures += hammerFailures;
            teamDetails.forces += forces;
            teamDetails.forceFailures += forceFailures;
            teamDetails.steals += steals;
            teamDetails.stealFailures += stealFailures;
            teamDetails.concededSteals += concededSteals;
            teamDetails.concededStealFailures += concededStealFailures;
        }
        const displayableNumber = (num: number, addPercentSign: boolean):string => {
            return Number.parseFloat(num.toFixed(2)).toString() + (addPercentSign ? '%' : '');
        }
    
        const convertFullDetailsToSummarizedDetails = (fullDetails: FullDetails[]): SummarizedDetails[] => {
            return fullDetails.map(fd => {
                return {
                    id: fd.id,
                    name: fd.name,
                    team: fd.team,
                    wins: fd.wins,
                    losses: fd.losses,
                    ties: fd.ties,
                    averagePts: displayableNumber(fd.ptsFor.reduce((a, b) => a + b, 0) / fd.ptsFor.length, false),
                    averagePtsAgainst: displayableNumber(fd.ptsAgainst.reduce((a, b) => a + b, 0) / fd.ptsAgainst.length, false),
                    hammerEfficiency: displayableNumber((100* fd.hammerSuccesses / (fd.hammerSuccesses + fd.hammerFailures)), true),
                    forceEfficiency: displayableNumber(100* fd.forces / (fd.forces + fd.forceFailures), true),
                    stealEfficiency: displayableNumber(100* fd.steals / (fd.steals + fd.stealFailures), true),
                    stealDefenseEfficiency: displayableNumber(100* fd.concededSteals / (fd.concededSteals + fd.concededStealFailures), true),
                    hammerMinusSteal: displayableNumber(100*(fd.hammerSuccesses / (fd.hammerSuccesses + fd.hammerFailures)) - 100*(fd.concededSteals / (fd.concededSteals + fd.concededStealFailures)), true)
                } as SummarizedDetails;
            });
        };

        setData(getTeamStats());
    }, [filteredGames, selectedYears, selectedSemesters, selectedDivisions]);

    if(!games || games.length === 0){
        return (
            <></>
        );
    }

    return (
        <div>
            <h3>All Teams</h3>

            
            
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <FormControl>
                    <InputLabel>Years:</InputLabel>
                    <Select
                        multiple
                        value={selectedYears}
                        onChange={handleSelectedYearChanged}
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Semesters:</InputLabel>
                    <Select
                        multiple
                        value={selectedSemesters}
                        onChange={handleSelectedSemesterChanged}
                    >
                        {semesters.map((semester) => (
                            <MenuItem key={semester} value={semester}>
                                {semester}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Divisions:</InputLabel>
                    <Select
                        multiple
                        value={selectedDivisions}
                        onChange={handleSelectedDivisionChanged}
                    >
                        {divisions.sort().map((division) => (
                            <MenuItem key={division} value={division}>
                                {division}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
           
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid 
                        rows={data} 
                        columns={columns} 
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'team', sort: 'asc' }],
                            },
                        }}
                    
                    />
                </div>
            


           {/*  <div style={{display: 'flex'}}>
                <label style={{padding:'10px'}}>
                    Years:
                    <Select isMulti options={years.map((year) => ({ value: year, label: year }))} onChange={handleSelectedYearChanged} value={selectedYears.map((year) => ({ value: year, label: year}))} />
                </label>

                <label style={{padding:'10px'}}>
                    Semesters:
                    <Select isMulti options={semesters.map((semester) => ({ value: semester, label: semester }))} onChange={handleSelectedSemesterChanged} value={selectedSemesters.map((semester) => ({ value: semester, label: semester }))} />
                </label>

                <label style={{padding:'10px'}}>
                    Divisions:
                    <Select isMulti options={divisions.sort().map((division) => ({ value: division, label: division }))} onChange={handleSelectedDivisionChanged} value={selectedDivisions.map((division) => ({ value: division, label: division }))} />
                </label>

            </div>
            <div>
                <ReactDataGrid idProperty='id' columns={allStatisticsColumns} dataSource={getTeamStats()} style={gridStyle} />
            </div> */}
        </div>
    );
};